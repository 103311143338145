// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

var $ = require('jquery');


var ModalVideo = require('modal-video');
new ModalVideo('.js-modal-btn',{channel: 'vimeo'});

require('material-scrolltop');

require('../../assets/js/vendor/flickity.pkgd.min.js');

require('../../assets/js/vendor/slick.min.js');

// Scripts
require('./modules/scripts');





	









